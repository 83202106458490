@use 'global/mixin' as m;
@use 'global/variable' as v;
.p-indexMv {
  position: relative;
  height: calc(100vh - 90px);
  height: calc(100svh - 90px);
  background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/img_mv.png');
  background-size: cover;
  background-position: center;
  @include m.sp {
    background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/img_mv_sp.png');
    height: calc(100vh - clamp(50px,12vw,90px));
    height: calc(100svh - clamp(50px,12vw,90px));
  }
  &_txt {
    position: absolute;
    max-width: 460px;
    width: 40%;
    top: 36%;
    left: 12%;
    @include m.sp {
      width: 87%;
      top: 9%;
      left: 5%;
    }
  }
  &_btn {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.p-indexFeatureBg {
  position: relative;
  background: rgb(164,203,98);
  background: linear-gradient(90deg, rgba(164,203,98,0.2) 0%, rgba(255,203,98,0.2) 100%);
  &:before {
    position: absolute;
    content:"";
    clip-path: polygon(25% 0, 100% 0%, 75% 100%, 0% 100%);
    background: v.$white;
    display: block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
  }
}

.p-indexFeatureCont {
  position: relative;
}

.p-indexFeatureBox {
  display: grid;
  grid-template-columns: 48% 1fr;
  grid-gap: 0 40px;
  @include m.sp {
    display: flex;
    flex-direction: column;
  }
  &_img {
    grid-row: 1 / 4;
    grid-column: 1 / 2;
    @include m.sp {
      order: 2;
      margin: 0 0 40px;
    }
  }
  &_detail {
    grid-row: 1 / 2;
    grid-column: 2 / 3;
    margin: 0 0 30px;
    @include m.sp {
      order: 1;
    }
  }
  &_txt {
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &_btn {
    grid-row: 2 / 4;
    grid-column: 2 / 3;
    display: flex;
    align-items: flex-end;
    @include m.sp {
      order: 3;
      display: block;
      text-align: center;
    }
  }
}

.p-indexFeatureSec {
  margin: 0 0 90px;
  @include m.sp {
    margin: 0 0 60px;
  }
}

.p-indexCareerBox {
  display: grid;
  grid-template-columns: 1fr 48%;
  grid-gap: 0 40px;
  @include m.sp {
    display: flex;
    flex-direction: column;
  }
  &_img {
    grid-row: 1 / 4;
    grid-column: 2 / 3;
    @include m.sp {
      order: 2;
      margin: 0 0 40px;
    }
  }
  &_detail {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    margin: 0 0 30px;
    @include m.sp {
      order: 1;
    }
  }
  &_txt {
    @include m.sp {
      font-size: 1.2rem;
    }
  }
  &_btn {
    grid-row: 2 / 4;
    grid-column: 1 / 2;
    display: flex;
    align-items: flex-end;
    @include m.sp {
      order: 3;
      display: block;
      text-align: center;
    }
  }
}

.p-indexCmnTitle {
  margin: 0 0 35px;
  @include m.sp {
    margin: 0 0 20px;
  }
  &--center {
    text-align: center;
  }
  &--white {
    color: v.$white;
  }
  &_ja {
    font-size: 30px;
    display: block;
    font-weight: 500;
    margin: 10px 0 0;
    @include m.sp {
      font-size: 2.6rem;
    }
    &--mid {
      font-size: 24px;
      margin: 5px 0 0;
      @include m.sp {
        font-size: 2rem;
      }
    }
    &--white {
      color: v.$white;
    }
  }
  &_en {
    font-size: 24px;
    color: v.$green;
    @include m.sp {
      font-size: 2rem;
    }
    &--mid {
      font-size: 18px;
      @include m.sp {
        font-size: 1.4rem;
      }
    }
    &--white {
      color: v.$white;
    }
  }
}

.p-indexCatchSec {
  position: relative;
  @include m.sp {
    padding: 0 0 50px;
  }
}

.p-indexCatchGrd {
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgb(255,255,255);
  background: linear-gradient(103deg, rgba(255,255,255,0) 40%, rgba(164,203,98,1) 55%);
  @include m.sp {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 40%, #a4cb62 80%);
  }
}

.p-indexCatchImg {
  width: 51%;
  @include m.sp {
    width: 100%;
  }
}


.p-indexCatchArea {
  position: absolute;
  top: 0;
  right: 0;
  margin: auto;
  width: 45%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: v.$white;
  padding: 0 20px 0 0;
  @include m.sp {
    width: 100%;
    padding: 0;
    bottom: 60px;
    top: unset;
    height: auto;
    left: 0;
    right: 0;
    text-align: center;
    align-items: center;
  }
}

.p-indexCatchTitle {
  margin: 0 0 min(13%,70px);
  font-size: 24px;
  @include m.lineHeight(50,24);
  font-weight: 500;
  @include m.tab {
    margin: 0 0 10px;
  }
  @include m.sp {
    font-size: 1.8rem;
    margin: 0 0 min(8%,40px);
  }
}

.p-indexBtnWrap {
  text-align: center;

}

.p-indexCmnSec {
  padding: 100px 20px;
  @include m.sp {
    padding: 60px 10px;
  }
  &--bgGray {
    background: v.$gray-thin;
  }
}

.p-indexSlide {
  margin: 0 0 40px;
  position: relative;
  padding-top: 60%;
  @include m.sp {
    padding-top: 66.5%;
  }
  &_item {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.p-indexInterviewBg {
  background: rgb(164,203,98);
  background: linear-gradient(120deg, rgba(164,203,98,1) 65%, rgba(255,203,98,1) 100%);
}

.p-indexInterview {
  display: flex;
  gap: 5%;
  margin: 0 0 40px;
  @include m.sp {
    display: block;
  }
  &_item {
    width: 30%;
    @include m.sp {
      width: 100%;
      margin: 0 0 30px;
    }
  }
  &_imgWrap {
    margin: 0 0 20px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    overflow: hidden;
    height: 0;
    padding-bottom: 83%;
    position: relative;
    @include m.sp {
      padding-bottom: 60%;
    }
  }
  &_img {
    object-fit: cover;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  &_link {
    display: block;
    @include m.hoverOpa;
  }
  &_title {
    color: v.$white;
    font-size: 18px;
    margin: 0 0 10px;
  }
  &_tag {
    color: v.$white;
    font-size: 12px;
    border: 1px solid v.$white;
    border-radius: 20px;
    padding: 5px 8px;
    margin: 0 5px 5px 0;
    display: inline-block;
    line-height: 1;
  }
}

.p-indexRecruit {
  display: flex;
  gap: calc(14%/3);
  margin: 0 0 70px;
  justify-content: center;
  @include m.sp {
    display: block;
    margin: 0 0 40px;
  }
  &_item {
    width: 43%;
    border: 1px solid v.$gray-clear;    
    @include m.sp {
      width: 100%;
      margin: 0 0 30px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &_link {
    padding: 35px;
    display: block;
    @include m.hoverOpa;
    @include m.sp {
      padding: 10px;
    }
  }
  &_img {
    margin: 0 0 20px;
    @include m.sp {
      margin: 0 0 10px;
    }
  }
  &_title {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 500;
    @include m.sp {
      margin: 0 0 10px;
      font-size: 1.8rem;
    }
  }
  &_detail {
    display: flex;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &_term {
    width: 50px;
    @include m.sp {
      width: 15%;
    }
  }
  &_desc {
    width: calc(100% - 50px);
    text-indent: -1rem;
    padding-left: 1rem;
    @include m.sp {
      width: 85%;
    }
  }
}

.p-indexNumbersBg {
  background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/bg_leaf_top.png'), url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/bg_leaf_bottom.png');
  background-color: rgba(255,203,98,0.4);
  background-repeat: no-repeat;
  background-position: left top, right bottom;
  background-size: 1200px, 420px;
}

.p-indexNumbers {
  display: flex;
  flex-wrap: wrap;
  gap: calc(7%/2);
  @include m.sp {
    gap: 4%;
  }
  &_item {
    width: 31%;
    text-align: center;
    background: v.$white;
    padding: 20px;
    min-height: 250px;
    border-top-left-radius: 40px;
    border-bottom-right-radius: 40px;
    margin: 0 0 3.5%;
    background-repeat: no-repeat;
    @include m.sp {
      width: 48%;
      margin: 0 0 4%;
      padding: 10px;
      min-height: auto;
      display: flex;
      flex-direction: column;
    }
    &:nth-of-type(n+4) {
      margin: 0;
      @include m.sp {
        margin: 0 0 4%;
      }
    }
    &:nth-of-type(n+5) {
      @include m.sp {
        margin: 0;
      }
    }
    &--old {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_old.svg');
      background-size: 110px;
      background-position: left 95% bottom 10%;
      @include m.sp {
        background-size: 38%;
      }
    }
    &--amount {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_amount.svg');
      background-size: 176px;
      background-position: left 80% bottom 10%;
      @include m.sp {
        background-size: 60%;
        background-position: left 70% bottom 15%;
      }
    }
    &--childCare {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_childcare.svg');
      background-size: 120px;
      background-position: left 90% bottom 10%;
      @include m.sp {
        background-size: 45%;
      }
    }
    &--satisfaction {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_satisfaction.svg');
      background-size: 115px;
      background-position: left 90% bottom 20%;
      @include m.sp {
        background-size: 50%;
        background-position: left 90% bottom 10%;
      }
    }
    &--paidHoliday {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_paid_holiday.svg');
      background-size: 95px;
      background-position: left 90% bottom 20%;
      @include m.sp {
        background-size: 35%;
        background-position: left 85% bottom 15%;
      }
    }
    &--time {
      background-image: url('/recruit/wp-content/themes/wakaba_recruit/assets/img/index/icon_time.svg');
      background-size: 110px;
      background-position: left 90% bottom 20%;
      @include m.sp {
        background-size: 45%;
        background-position: left 85% bottom 15%;
      }
    }
  }
  &_title {
    font-size: 18px;
    margin: 0 0 10px;
    @include m.sp {
      font-size: 1.6rem;
      margin: 0 0 5px;
    }
  }
  &_bdr {
    width: 130px;
    display: block;
    margin: 0 auto;
    height: 1px;
    background-color: v.$black-iron;
    @include m.sp {
      width: 40%;
    }
  }
  &_numWrap {
    margin: 10px 0 20px;
    @include m.sp {
      margin: 10px 0;
      &--childCare {
        margin: auto 0 10px;
      }
    }
  }
  &_num {
    font-size: 100px;
    color: v.$green;
    display: inline-block;
    line-height: 1;
    @include m.sp {
      font-size: 6rem;
    }
  }
  &_unit {
    font-size: 40px;
    font-weight: 500;
    @include m.sp {
      font-size: 2rem;
    }
    &--bebas {
      font-family: 'Bebas';
    }
  }
  &_tyu {
    font-size: 14px;
    @include m.sp {
      font-size: 12px;
    }
  }
}

.p-indexCmnIntro {
  text-align: center;
  margin: 0 0 40px;
  @include m.sp {
    font-size: 1.4rem;
  }
}

.p-indexSlideSoon {
  max-width: 880px;
  width: 100%;
  margin: 0 auto 40px;
}