@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-messageWrap {
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
}

.p-messageCatch {
  font-size: 22px;
  font-weight: 500;
  margin: 0 0 40px;
  @include m.sp {
    font-size: 1.6rem;
    margin: 30px 0;
  }
}

.p-messagePara {
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.4rem;
    margin: 0 0 20px;
  }
}

.p-messageMvWrap {
  margin: 0 0 40px;
  @include m.sp {
    margin: 0 0 20px;
  }
}

.p-messageBtm {
  display: flex;
  border-top: 1px solid v.$black-iron;
  padding: 30px 0 0;
  @include m.sp {
    padding: 20px 0 0;
    display: block;
  }
  gap: 20%;
  &_state {
    font-size: 14px;
    margin: 0 0 10px;
    @include m.sp {
      margin: 0;
      font-size: 1.2rem;
    }
  }
  &_name {
    margin: 5px 0 0;
    &--ohiso {
      width: 85px;
    }
    &--nakayama {
      width: 95px;
    }
  }
  &_item {
    @include m.sp {
      margin: 0 0 20px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
}
