@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-arrow {
  border-top: 1px solid v.$black-iron;
  border-left: 1px solid v.$black-iron;
  display: inline-block;
  width: 10px;
  height: 10px;
  &--left {
    margin: 0 15px 0 0;
    transform: rotate(-45deg);
  }
  &--right {
    margin: 0 0 0 15px;
    transform : rotate(135deg);
  }
}