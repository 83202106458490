@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-welfare {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include m.sp {
    display: block;
  }
  &_item {
    width: 47%;
    display: flex;
    align-items: center;
    position: relative;
    padding: 15px 0;
    margin: 0 0 20px;
    font-size: 18px;
    @include m.sp {
      font-size: 1.6rem;
      width: 100%;
      padding: 5px 0;
    }
    &--crr {
      font-size: 16px;
      @include m.sp {
        font-size: 1.6rem;
      }
    }
    &:nth-of-type(n+7) {
      margin: 0;
      @include m.sp {
        margin: 0 0 20px;
      }
    }
    
  }
  &_icon {
    border-radius: 50%;
    border: 2px solid v.$green-clear;
    width: 100px;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 20px;
    position: absolute;
    background: v.$white;
    &--crr {
      width: 80px;
      height: 80px;
      padding: 15px;
    }
    @include m.sp {
      width: 80px;
      height: 80px;
      padding: 15px;
    }
  }
  &_iconImg {
    height: 100%;
    margin: 0 auto;
  }
  &_cont {
    background-color: v.$green-thin;
    min-height: 70px;
    display: flex;
    align-items: center;
    padding: 0 10px 0 80px;
    width: 90%;
    margin: 0 0 0 10%;
    font-weight: 500;
    &--ccr {
      padding: 0 10px 0 60px;
    }
    @include m.sp {
      padding: 0 20px 0 60px;
      
    }
  }
  &_txtSmall {
    font-size: 14px;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &_txt {
    line-height: 1.2;
  }
}