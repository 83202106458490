@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-workImg {
  margin: 0 0 40px;
  &--01 {
    max-width: 590px;
    margin: 0 auto 70px;
    @include m.sp {
      margin: 0 auto 60px;
    }
  }
}

.p-workColumns {
  display: flex;
  justify-content: space-between;
  margin: 0 0 40px;
  @include m.sp {
    display: block;
    margin: 0 0 60px;
  }
  &:last-of-type {
    margin: 0;
  }
  &_colLeft {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 20%;
    text-align: center;
    border-right: 2px solid;
    @include m.sp {
      width: 100%;
      border-right: 0;
      border-bottom: 2px solid;
      padding: 0 0 20px;
      margin: 0 0 20px;
    }
    &--repair {
      border-color: v.$blue;
    }
    &--ma {
      border-color: v.$orange-light;
    }
    &--inheritance {
      border-color: v.$red;
    }
    &--growth {
      border-color: v.$green-light;
    }
    &--ipo {
      border-color: v.$orange-deep;
    }
    &--tax {
      border-color: v.$purple;
    }
    
  }
  &_colRight {
    width: 76%;
    @include m.sp {
      width: 100%;
    }
  }
  &_txt {
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &_img {
    display: inline-block;
    margin: 0 0 10px;
    &--repair {
      width: 33px;
      @include m.sp {
        width: 3.3rem;
      }
    }
    &--ma {
      width: 42px;
      @include m.sp {
        width: 4.2rem;
      }
    }
    &--inheritance {
      width: 30px;
      @include m.sp {
        width: 3rem;
      }
    }
    &--growth {
      width: 33px;
      @include m.sp {
        width: 3.3rem;
      }
    }
    &--ipo {
      width: 18px;
      @include m.sp {
        width: 1.8rem;
      }
    }
    &--tax {
      width: 35px;
      @include m.sp {
        width: 3.5rem;
      }
    }
  }
  &_name {
    font-size: 14px;
    @include m.sp {
      font-size: 1.6rem;
    }
    &--repair {
      color: v.$blue;
    }
    &--ma {
      color: v.$orange-light;
    }
    &--inheritance {
      color: v.$red;
    }
    &--growth {
      color: v.$green-light;
    }
    &--ipo {
      color: v.$orange-deep;
    }
    &--tax {
      color: v.$purple;
    }
  }
}

.p-careerImgDesc {
  margin: 70px 0 40px;
  @include m.sp {
    margin: 30px 0 20px;
  }
  &_term {
    font-size: 18px;
    font-weight: 500;
    margin: 0 0 10px;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
  &_desc {
    line-height: 1.8;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}

.p-workCaseWrap {
  max-width: 840px;
  margin: 0 auto;
}

.p-workCircleCol {
  display: flex;
  text-align: center;
  justify-content: center;
  gap: 50px;
  margin: 0 0 50px;
  @include m.tab {
    gap: 30px;
  }
  @include m.sp {
    display: block;
  }
}

.p-workCircle {
  width: 25%;
  border-radius: 50%;
  background: v.$white;
  width: 150px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @include m.tab {
    width: 140px;
    height: 140px;
  }
  @include m.sp {
    width: 100%;
    border-radius: 50px;
    flex-direction: unset;
    align-items: center;
    margin: 0 auto 10vw;
    justify-content: flex-start;
    height: auto;
    padding: 10px 20px;
  }
  &:after,
  &:before {
    content: "";
    position: absolute;
    height: 1px;
    background: v.$black-iron;
    top: 0;
    bottom: 0;
    right: -40px;
    width: 30px;
    margin: auto;
    @include m.tab {
      right: -27px;
      width: 20px;
    }
    @include m.sp {
      top: unset;
      bottom: -5.5vw;
      right: 0;
      left: 0;
      width: 9%;
      z-index: 2;
    }
  }
  &:after {
    transform: rotate(45deg);
  }
  &:before {
    transform: rotate(135deg);
  }
  &:last-of-type {
    &:before {
      display: none;
    }
    &:after {
      display: none;
    }
  }
  &--repair {
    border: 2px solid v.$blue;
  }
  &--growth {
    border: 2px solid v.$green-light;
  }
  &--tax {
    border: 2px solid v.$purple;
  }
  &--inheritance {
    border: 2px solid v.$red;
  }
  &_icon {
    &--repair {
      width: 33px;
    }
    &--growth {
      width: 33px;
    }
    &--tax {
      width: 35px;
    }
    &--inheritance {
      width: 30px;
    }
    @include m.sp {
      width: 8%;
      margin: 0 20px 0 0;
    }
  }
  &_txt {
    margin: 10px 0 0;
    font-size: 14px;
    @include m.tab {
      margin: 5px 0 0;
    }
    @include m.sp {
      font-size: 1.4rem;
      margin: 0;
      &:before {
        content:"";
        width: 11vw;
        height: 3px;
        background: v.$gray-thin;
        left: 0;
        right: 0;
        margin: auto;
        bottom: -2px;
        position: absolute;
        z-index: 1;
      }
      &:after {
        content:"";
        width: 11vw;
        height: 3px;
        background: v.$gray-thin;
        left: 0;
        right: 0;
        margin: auto;
        top: -2px;
        position: absolute;
        z-index: 1;
      }
    }
    &--repair {
      color: v.$blue;
      @include m.sp {
        &:after {
          display: none;
        }
      }
    }
    &--growth {
      color: v.$green-light;
    }
    &--tax {
      color: v.$purple;
    }
    &--inheritance {
      color: v.$red;
      &:before {
        @include m.sp {
          bottom: unset;
        top: -2px;
        }
      }
    }
  }
}

.p-workCaseTitle {
  border-bottom: 1px solid v.$black-iron;
  display: flex;
  margin: 0 0 30px;
  &_en {
    color: v.$white;
    background: v.$black-iron;
    font-size: 20px;
    padding: 8px 20px 2px;
    line-height: 1;
    display: inline-block;
    letter-spacing: 2px;
    @include m.sp {
      font-size: 1.8rem;
    }
  }
  &_ja {
    font-size: 20px;
    margin: 0 0 0px 10px;
    font-weight: 500;
    @include m.sp {
      font-size: 1.6rem;
    }
  }
}

.p-workCaseInnerTitle {
  font-size: 18px;
  border-left: 5px solid v.$green;
  padding: 0 0 3px 10px;
  line-height: 1;
  font-weight: 500;
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.6rem;
  }
}

.p-workCaseCols {
  display: flex;
  justify-content: space-between;
  @include m.sp {
    display: block;
  }
  &_imgWrap {
    width: min(calc(45/84*100%),450px);
    @include m.sp {
      width: 100%;
      margin: 0 0 20px;
    }
  }
  &_info {
    width: min(calc(35/84*100%),350px);
    @include m.sp {
      width: 100%;
    }
  }
}

.p-workCaseLists {
  &_item {
    padding: 0 0 0 25px;
    background: url(/recruit/wp-content/themes/wakaba_recruit/assets/img/img_check.png);
    background-repeat: no-repeat;
    background-position: left 5px;
    margin: 0 0 10px;
    background-size: 20px;
    @include m.sp {
      font-size: 1.4rem;
      background-position: left 0.2rem;
      background-size: 6%;
    }
  }
}

.p-workCaseFlowSec {
  display: flex;
  margin: 30px 0 0;
  min-height: 130px;
  &+& {
    margin: 0 0 0;
  }
}

.p-workCaseFlowDots {
  border-left: 5px dotted v.$gray-clear;
  margin: 40px 40px 0 0;
  @include m.sp {
    margin: 35px 0 0 0;
  }
  &:before {
    content:"";
    width: 25px;
    height: 25px;
    display: block;
    position: relative;
    left: -14px;
    top: -30px;
    border-radius: 20px;
    border: 3px solid v.$white;
    @include m.sp {
      width: 1.5rem;
      height: 1.5rem;
      min-width: 20px;
      min-height: 20px;
      left: -12px;
      top: -25px;
    }
  }
  &--blue {
    &:before {
    background: v.$blue;
    }
  }
  &--green {
    &:before {
    background: v.$green-light;
    }
  }
  &--purple {
    &:before {
    background: v.$purple;
    }
  }
  &--red {
    &:before {
    background: v.$red;
    }
  }
}

.p-workCaseFlowCont {
  &_title {
    font-weight: 500;
    margin: 0 0 10px;
    @include m.sp {
      font-size: 1.6rem;
    }
    &--blue {
      color: v.$blue;
    }
    &--green {
      color: v.$green-light;
    }
    &--purple {
      color: v.$purple;
    }
    &--red {
      color: v.$red;
    }
  }
  &_lists {
    @include m.sp {
      margin: 0 0 20px;
    }
  }
  &_listsItem {
    line-height: 1.8;
    position: relative;
    padding: 0 0 0 1.5rem;
    @include m.sp {
      padding: 0 0 0 2rem;
      font-size: 1.4rem;
    }
    &:before {
      content: "";
      position: absolute;
      background: #3c3c3c;
      border-radius: 10px;
      width: 10px;
      height: 10px;
      top: 0.6rem;
      left: 5px;
      @include m.sp {
        width: 1rem;
        height: 1rem;
        top: 0.8rem;
        left: 5px;
      }
    }
  }
}

.p-workCaseBoxWhite {
  background: v.$white;
  padding: 40px;
  display: flex;
  justify-content: space-between;
  @include m.sp {
    padding: 20px;
    display: block;
  }
  &_imgWrap {
    width: min(calc(25/75*100%),250px);
    @include m.sp {
      width: 100%;
      margin: 0 0 20px;
    }
  }
  &_lists {
    width: min(calc(46/75*100%),460px);
    @include m.sp {
      width: 100%;
      margin: 0 0 20px;
    }
  }
}

.p-workCaseConsulList {
  display: flex;
  align-items: center;
  margin: 0 0 30px;
  @include m.sp {
    justify-content: space-between;
  }
  &:last-of-type {
    margin: 0;
  }
  &_term {
    width: 200px;
    position: relative;
    margin: 0 30px 0 0;
    @include m.tab {
      min-width: 150px;
      width: 150px;
    }
    @include m.sp {
      width: 47%;
      min-width: 130px;
      margin: 0 10% 0 0;
    }
    &:after {
      position: absolute;
      content:"";
      top: 0;
      right: -20px;
      bottom: 0;
      margin: auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 12.5px 0 12.5px 10px;
      @include m.sp {
        right: -13%;
        border-width: 10px 0 10px 8px;
      }
    }
    &--repair {
      &:after {
        border-color: transparent transparent transparent v.$blue;
      }
    }
    &--growth {
      &:after {
        border-color: transparent transparent transparent v.$green-light;
      }
    }
    &--tax {
      &:after {
        border-color: transparent transparent transparent v.$purple;
      }
    }
    &--inheritance {
      &:after {
        border-color: transparent transparent transparent v.$red;
      }
    }
  }
  &_txt {
    font-size: 14px;
    font-weight: 500;
    flex-grow: 1;
    color: v.$black;
    @include m.sp {
      font-size: 1.2rem;
      width: 50%;
    }
  }
}