@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-heading {
  &Atc {
    margin: 0 0 30px;
    @include m.sp {
      margin: 0 0 20px;
    }
    &--mT {
      margin: 60px 0 30px;
      @include m.sp {
        margin: 40px 0 20px;
      }
    }
  }
}