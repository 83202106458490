@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-career {
  &ImgWrap {
    margin: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include m.sp {
      display: block;
    }
    &_item {
      width: min(28%,250px);
      position: relative;
      @include m.sp {
        width: min(70%,250px);
        margin: 0 auto 60px;
      }
      &:after {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 0 15px 15px;
        border-color: transparent transparent transparent v.$orange;
        right: -18%;
        top: 50%;
        margin: auto;
        @include m.sp {
          top:unset;
          right: 0;
          left: 0;
          bottom: -40px;
          border-width: 15px 15px 0 15px;
          border-color: v.$orange transparent transparent transparent;
        }
      }
      &--01 {
        padding: 20px 0 0;
        &:after {
          top: 45%;
          @include m.sp {
            top:unset;
            right: 0;
            left: 0;
            bottom: -40px;
          }
        }
      }
      &--03 {
        &:after { 
          display: none;
        }
      }
      
    }
  }
  &AboutPartner {
    max-width: 640px;
    width: 100%;
    margin: 0 auto;
    &_term {
      margin: 0 0 10px;
      font-weight: 500;
      @include m.sp {
        font-size: 1.6rem;
      }
    }
    &_desc {
      font-size: 14px;
      line-height: 1.8;
      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }
  &ImgBox {
    margin: 0 0 20px;
    &_title {
      font-weight: bold;
      text-decoration: underline;
      @include m.sp {
        font-size: 1.4rem;
        margin: 0 0 20px;
      }
    }
    &_inner {
      &--02 {
        max-width: 540px;
        margin: 0 auto 30px;
        @include m.sp {
          margin: 0 0 20px;
        }
      }
      &--03 {
        max-width: 710px;
        margin: 0 auto 30px;
      }
    }
    &_listsItem {
      padding: 0 0 0 1.8rem;
      background: url(/recruit/wp-content/themes/wakaba_recruit/assets/img/img_check.png);
      background-repeat: no-repeat;
      background-position: 5px 2px;
      background-size: 20px;
      margin: 0 0 5px;
      font-size: 14px;
      @include m.sp {
        background-position: 2px 2px;
        background-size: 1.6rem;
        margin: 0 0 5px;
        font-size: 1.2rem;
      }
    }
  }  
}

.p-careerBgBox {
  background-image: url(/recruit/wp-content/themes/wakaba_recruit/assets/img/career_plan/bg_leaf_top.png),url(/recruit/wp-content/themes/wakaba_recruit/assets/img/career_plan/bg_leaf_bottom.png);
  background-color: rgba(255,203,98,.25);
  background-repeat: no-repeat;
  background-position: left top,right bottom;
  background-size: 800px,280px;
  padding: 50px 140px;
  text-align: center;
  @include m.tab {
    padding: 50px;
  }
  @include m.sp {
    padding: 40px 10px;
  }
  &_title {
    font-size: 22px;
    color: v.$green;
    margin: 0 0 30px;
    font-weight: 500;
    @include m.sp {
      font-size: 1.6rem;
      margin: 0 0 20px;
    }
  }
  &_txt {
    line-height: 2;
    margin: 0 0 30px;
    @include m.sp {
      font-size: 1.4rem;
      margin: 0 0 20px;
    }
  }
  &_circleWrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_circle {
    background: v.$white;
    border-radius: 100%;
    width: 190px;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include m.sp {
      width: clamp(147px,43vw,180px);
      height: clamp(147px,40vw,180px);
    }
  }
  &_circleTxt {
    color: v.$green;
    font-size: 14px;
    line-height: 2;
    @include m.sp {
      font-size: min(1.15rem,15px);
      line-height: 1.8;
    }
  }
  &_cross {
    width: 30px;
    height: 30px;
    position: relative;
    margin: 0 15px;
    @include m.sp {
      width: 20px;
      height: 20px;
      margin: 0 10px;  
    }
  }
  &_crossBdr {
    height: 4px;
    background: v.$green-clear;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    @include m.sp {
      height: 3px;
    }
    &--01 {
      transform: rotate(45deg);
    }
    &--02 {
      transform: rotate(-45deg);
    }
  }
}

.p-careercolumns {
  display: flex;
  justify-content: space-between;
  margin: 0 0 80px;
  gap: 40px;
  @include m.sp {
    margin: 0 0 40px;
    display: block;
  }
  &_columnImg {
    max-width: 340px;
    width: 100%;
    @include m.sp {
      max-width: none;
      margin: 0 0 20px;
    }
  }
  &_column {
    max-width: 520px;
    width: 100%;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}

.p-careerTitle {
  font-size: 20px;
  color: v.$green;
  font-weight: 500;
  margin: 0 0 20px;
  @include m.sp {
    font-size: 1.8rem;
  }
}
