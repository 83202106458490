@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-news {
  &Wrap {
    max-width: 900px;
    margin: 0 auto 30px;
    border-top: 1px solid v.$gray;
    border-bottom: 1px solid v.$gray;
    padding: 30px 0;
  }
}
