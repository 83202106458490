@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-btnWrap {
  &--center {
    text-align: center;
  }
  &--mb {
    margin: 0 0 70px;
  }
}