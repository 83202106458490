@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-header {
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 100%;
  pointer-events: none;
  &_inner {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: v.$white;
    pointer-events: all;
    @include m.sp {
      padding: 10px;
    }
  }
  &_left {
    display: flex;
    align-items: center;
  }
  &_logoLink {
    max-width: 270px;
    width: 100%;
    @include m.sp {
      max-width: 160px;
    }
  }
  &_name {
    margin: 0 0 0 20px;
    padding: 0 0 0 20px;
    border-left: v.$black-iron 1px solid;
    @include m.sp {
      margin: 0 0 0 10px;
      padding: 0 0 0 10px;
    }
  }
  &_nameJa {
    font-size: 14px;
    @include m.sp {
      font-size: 1rem;
    }
  }
  &_nameEn {
    font-size: 16px;
    @include m.sp {
      font-size: 1rem;
    }
  }
  &_right {
    width: 30px;
    height: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: pointer;
    &.is-open {
      
    }
  }
  &_bdr {
    display: block;
    width: 100%;
    height: 2px;
    background: v.$gray;
  }
}

.l-headerMenuBtn {
  .l-header_bdr {
    &--top {
      animation: .3s ease 0s forwards spNavBtnTopCls;
    }
    &--bottom {
      animation: .3s ease 0s forwards spNavBtnBtmCls;
    }
  }
  &.is-open {
    .l-header_bdr {
      &--top {
        animation: .3s ease 0s forwards spNavBtnTopOpn;
      }
      &--middle {
        display: none;
      }
      &--bottom {
        animation: .3s ease 0s forwards spNavBtnBtmOpn;
      }
    }
  }
}

.l-headerNav {
  display: none;
  background: rgb(164,203,98);
  background: linear-gradient(45deg, rgba(164,203,98,1) 65%, rgba(255,203,98,1) 100%);
  height: calc(100% - clamp(50px,10vw,90px));
  text-align: center;
  color: v.$white;
  pointer-events: all;
  &_inner {
    height: 100%;
    overflow-y: auto;
    padding: 20px 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &_lists {
    margin: auto;
  }
  &_item {
    font-size: 24px;
    margin: 0 0 5vh;
    @include m.hoverOpa;
    @include m.sp {
      font-size: 18px;
    }
    &:last-of-type {
      margin: 0;
    }
  }
  &_lists {
    
  }
}