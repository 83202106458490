@charset "UTF-8";

/*colors*/

$green: #50aa6e;
$green-clear: #a4cb62;
$green-thin: #edf5e0;
$green-light: #64bb46;

$black: #000;
$black-iron: #3c3c3c;

$gray: #9d9d9d;
$gray-clear: #c4c4c4;
$gray-ash: #b1b1b1;
$gray-thin: #f5f5f5;
$gray-thin02: #e8e8eb;

$orange-light: #f2bc0c;
$orange: #ffcb62;
$orange-clear: #fff5e0;
$orange-deep: #e5780b;

$white: #fff;

$blue: #29a3cc;

$red: #cc4929;

$purple: #994c86;



/* font family*/
$noto: 'Noto Sans JP', sans-serif;
$jose: 'Josefin Sans', sans-serif;
$Bebas: 'Bebas', sans-serif;
$Quick: 'Quicksand', sans-serif;

/*animation*/

@keyframes spNavBtnTopOpn {
    0% {
        margin-bottom: 8px;
    }
    50% {
        margin-bottom: -1px;
        transform: rotate(0);
    }
    100% {
        margin-bottom: -1px;
        transform: rotate(35deg);
    }
}

@keyframes spNavBtnBtmOpn {
    0% {
        margin-top: 8px;
    }
    50% {
        margin-top: -1px;
        transform: rotate(0);
    }
    100% {
        margin-top: -1px;
        transform: rotate(-35deg);
    }
}

@keyframes spNavBtnTopCls {
    0% {
        margin-bottom: -1px;
        transform: rotate(35deg);
    }
    50% {
        margin-bottom: -1px;
        transform: rotate(0);
    }
    100% {
        margin-bottom: 8px;
        transform: rotate(0);
    }
}

@keyframes spNavBtnBtmCls {
    0% {
        margin-top: -1px;
        transform: rotate(-35deg);
    }
    50% {
        margin-top: -1px;
        transform: rotate(0);
    }
    100% {
        margin-top: 8px;
        transform: rotate(0);        
    }
}