@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-tag {
  color: v.$white;
  border: 1px solid v.$white;
  border-radius: 20px;
  padding: 5px 20px;
  display: inline-block;
  line-height: 1;
  &--green {
    color: v.$green;
    border: 1px solid v.$green;
    background: v.$white;
    font-size: 14px;
    @include m.sp {
      font-size: 1.2rem;
    }
    &:hover {
      color: v.$white;
      background: v.$green;
    }
    &.is-active {
      color: v.$white;
      background: v.$green;
    }
  }
  &--gray {
    color: v.$gray-ash;
    padding: 5px 8px;
    border: 1px solid v.$gray-ash;
    background: v.$white;
    font-size: 12px;
  }
  &--white {
    color: v.$white;
    padding: 5px 8px;
    border: 1px solid v.$white;
    font-size: 12px;
  }
}