@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-heading {
  border-top: solid 10px v.$green-clear;
  padding: 0 20px;
  position: relative;
  min-height: 270px;
  display: flex;
  align-items: center;
  @include m.tab {
    padding: 0 20px 0 min(18vw,180px);
    min-height: 200px;
  }
  @include m.sp {
    padding: 20px 20px 20px 15%;
    min-height: 100px;
  }
  &:before {
    content:"";
    left: 0;
    top: 0;
    position: absolute;
    background: v.$green-clear;
    clip-path: polygon(0 0, 90% 0, 30% 100%, 0% 100%);
    height: 100%;
    width: 220px;
    @include m.tab {
      width: 20%;
    }
    @include m.sp {
      width: 17%;
      top: -3px;
    }
  }
  &_title {
    font-weight: 500;
    font-size: 42px;
    max-width: 1000px;
    margin: 0 auto;
    width: 70%;
    &--fMid {
      font-size: 28px;
    }
    @include m.tab {
    width: 100%;
    }
    @include m.sp {
      width: 100%;
      font-size: 2rem;
    }
  }
  &_titleEn {
    font-size: 24px;
    color: v.$green-clear;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &Atc {
    &Big {
      font-size: 24px;
      font-weight: 500;
      padding: 2px 0 2px 15px;
      border-left: 6px solid v.$green-clear;
      letter-spacing: 2px;
      line-height: 1.6;
      @include m.sp {
        font-size: 1.8rem;
      }
      &--pt2 {
        border-left: 6px solid v.$green;
      }
      &_small {
        font-size: 20px;
        @include m.sp {
          font-size: 1.6rem;
        }
      }
    }
    &Mid {
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid v.$gray;
      padding: 0 0 5px;
      position: relative;
      @include m.sp {
        font-size: 1.6rem;
      }
      &:before {
        content:"";
        position: absolute;
        bottom: -2px;
        left: 0;
        height: 3px;
        width: 100px;
        background: v.$green-clear;
      }
    }
    &Sml {
      font-size: 18px;
      font-weight: 500;
      background: v.$gray-thin;
      border-top-right-radius: 20px;
      padding: 10px;
      @include m.sp {
        font-size: 1.4rem;
      }
    }
    &Black {
      font-size: 22px;
      font-weight: 500;
      @include m.sp {
        font-size: 1.6rem;
      }
    }
  }
  &CmnTitle {
    margin: 0 0 35px;
    @include m.sp {
      margin: 0 0 20px;
    }
    &--center {
      text-align: center;
    }
    &--white {
      color: v.$white;
    }
    &_ja {
      font-size: 30px;
      display: block;
      font-weight: 500;
      margin: 10px 0 0;
      @include m.sp {
        font-size: 2.6rem;
      }
      &--mid {
        font-size: 24px;
        margin: 5px 0 0;
        @include m.sp {
          font-size: 2rem;
        }
      }
      &--white {
        color: v.$white;
      }
    }
    &_en {
      font-size: 24px;
      color: v.$green;
      @include m.sp {
        font-size: 2rem;
      }
      &--mid {
        font-size: 20px;
        @include m.sp {
          font-size: 1.4rem;
        }
      }
      &--sml {
        font-size: 18px;
        @include m.sp {
          font-size: 1.4rem;
        }
      }
      &--white {
        color: v.$white;
      }
    }
  }
  &Lines {
    display: flex;
    align-items: center;
    justify-content: center;
    &_line {
      height: 1px;
      background: v.$black-iron;
      width: 50%;
    }
    &_txt {
      padding: 0 20px;
      min-width: 200px;
      text-align: center;
      font-weight: 500;
      font-size: 20px;
      @include m.sp {
        font-size: 1.8rem;
        padding: 0 10px;
        min-width: 170px;
      }
    }
  }
}