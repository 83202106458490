@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-footer {
  &_btnWrap {
    text-align: right;
    margin: 0 0 30px;
  }
}

.l-footerInner {
  &_logoLink {
    max-width: 270px;
    width: 100%;
    display: block;
    @include m.sp {
      max-width: 160px;
    }
  }
}

.l-footerCont {
  display: flex;
  margin: 40px 0 0;
  justify-content: space-between;
  font-size: 14px;
  @include m.sp {
    display: block;
    margin: 20px 0 0;
  }
  &_left {
    border-right: 1px solid v.$black-iron;
    width: 50%;
    @include m.sp {
      width: 100%;
      border-bottom: 1px solid #3c3c3c;
      border-right: 0;
      padding: 0 0 20px;
      margin: 0 0 20px;
    }
  }
  &_right {
    width: 50%;
    padding: 0 0 0 50px;
    @include m.sp {
      width: 100%;
      padding: 0;
    }
  }
}

.l-footerCompanyTitle {
  font-weight: bold;
}

.l-footerCompany {
  display: flex;
  &_add {
    margin: 0 50px 0 0;
    @include m.sp {
      width: 50%;
      padding: 0 10px 0 0;
      margin: 0;
    }
  }
}

.l-footerNavLists {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  @include m.sp {
    width: 100%;
  }
  &_item {
    width: 50%;
    margin: 0 0 10px;
  }
  &_link {
    @include m.hoverOpa;
  }
}

.l-footerCopy {
  text-align: center;
  font-size: 12px;
  margin: 50px 0 10px;
  font-weight: 500;
  @include m.sp {
    margin: 30px 0 10px;
  }
}