@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-atc {
  &Itv {
    &_imgWrap {
      border-top-left-radius: 40px;
      border-bottom-right-radius: 40px;
      overflow: hidden;
      height: 0;
      padding-bottom: 83%;
      position: relative;
      @include m.sp {
        padding: 0 0 min(70%,540px);
      }
    }
    &_img {
      object-fit: cover;
      position: absolute;
      width: 100%;
      height: 100%;
    }
    &_link {
      display: block;
      @include m.hoverOpa;
    }
    &_title {
      font-size: 18px;
      &--white {
        color: v.$white;
      }
    }
  }
  &Date {
    font-size: 16px;
    font-family: v.$jose;
    font-weight: 300;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &Sns {
    width: 40px;
    display: inline-block;
    @include m.hoverOpa;
  }
  &Mv {
    border-top-left-radius: 50px;
    overflow: hidden;
    border-bottom-right-radius: 50px;
    overflow: hidden;
    height: 0;
    padding: 0 0 min(70%,540px);
    position: relative;
    &_img {
      position: absolute;
      object-fit: cover;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
    }
  }
  &SecImgCap {
    font-size: 14px;
  }
}
