@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-fontNotoThin {
  font-family: v.$noto;
  font-weight: 100;
}

.c-fontNotoL {
  font-family: v.$noto;
  font-weight: 300;
}

.c-fontNotoR {
  font-family: v.$noto;
  font-weight: 400;
}

.c-fontNotoM {
  font-family: v.$noto;
  font-weight: 500;
}

.c-fontNotoB {
  font-family: v.$noto;
  font-weight: 700;
}

.c-fontNotoBk {
  font-family: v.$noto;
  font-weight: 900;
}

.c-fontJoseThin {
  font-family: v.$jose;
  font-weight: 100;
}

.c-fontJoseEL {
  font-family: v.$jose;
  font-weight: 200;
}

.c-fontJoseEL {
  font-family: v.$jose;
  font-weight: 200;
}

.c-fontJoseL {
  font-family: v.$jose;
  font-weight: 300;
}

.c-fontJoseR {
  font-family: v.$jose;
  font-weight: 400;
}

.c-fontJoseM {
  font-family: v.$jose;
  font-weight: 500;
}

.c-fontJoseSB {
  font-family: v.$jose;
  font-weight: 600;
}

.c-fontJoseB {
  font-family: v.$jose;
  font-weight: 700;
}

.c-fontBebasR {
  font-family: v.$Bebas;
}

.c-fontQuickR {
  font-family: v.$Quick;
}