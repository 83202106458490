@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-btn {
  padding: 28px;
  display: inline-flex;
  @include m.hoverOpa;
  @include m.sp {
    padding: 15px;
  }
  &--green {
    background: v.$green;
  }
  &--gray {
    background: v.$black-iron;
  }
  &--middle {
    max-width: 330px;
    width: 100%;
    @include m.sp {
      max-width: 200px;
    }
  }
  &--big {
    max-width: 380px;
    width: 100%;
    @include m.sp {
      max-width: 250px;
    }
  }
  &_txt {
    font-size: 16px;
    margin: auto;
    @include m.sp {
      font-size: 1.4rem;
    }
    &--white {
      color: v.$white;
    }
  }
  &_imgBlank {
    width: 18px;
    @include m.sp {
      width: 3.5vw;
    }
  }
  &_imgArrow {
    width: 12px;
    @include m.sp {
      width: 2.5vw;
    }
  }
}