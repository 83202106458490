@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-author {
  background: v.$gray-thin;
  padding: 20px 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include m.sp {
    padding: 20px;
  }
  &_imgWrap {
    width: 110px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
    height: 0;
    padding: 0 0 min(17%,110px);
  }
  &_img {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &_info {
    width: calc(100% - 130px);
  }
  &_name {
    margin: 0 0 10px;
    font-weight: 500;
  }
  &_nameSml {
    font-size: 14px;
    margin: 0 10px 0 0;
  }
  &_nameMdl {
    font-size: 16px;
  }
  &_txt {
    font-size: 14px;
  }
}