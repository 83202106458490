@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-columns {
  display: flex;
  gap: 50px 5%;
  margin: 0 0 40px;
  flex-wrap: wrap;
  @include m.sp {
    display: block;
    margin: 0 0 30px;
  }
  &_item {
    width: 30%;
    @include m.sp {
      width: 100%;
      margin: 0 0 30px;
      &:last-of-type {
        margin: 0;
      }
    }
  }
  &_imgWrap {
    margin: 0 0 20px;
  }
  &_tag {
    margin: 0 5px 5px 0;
  }
  &_title {
    margin: 0 0 10px;
  }
}