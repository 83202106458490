@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-newsWrap {
  max-width: 720px;
  width: 100%;
  margin: 0 auto 70px;
  @include m.sp {
    margin: 0 auto 40px;
  }
}

.c-news {
  border-bottom: 1px solid v.$gray;
  padding: 0 0 30px;
  margin: 0 0 30px;
  &:last-of-type {
    margin: 0;
    padding: 0;
    border: none;
  }
  &_link {
    display: flex;
    justify-content: space-between;
    @include m.hoverOpa;
  }
  &_date {
    font-size: 16px;
    margin: 0 0 10px;
    display: inline-block;
    letter-spacing: 1px;
    @include m.sp {
      font-size: 1.6rem;
    }
    &--col {
      margin: 0 20px 0 0;
      @include m.sp {
        margin: 0 0 10px;
      }
    }
  }
  &_thumb {
    width: 20%;
    padding: 0 0 15.5%;
    position: relative;
    height: 0;
    @include m.sp {
      width: 30%;
      padding: 0 0 22%;
    }
    &--acv {
      width: 14%;
      padding: 0 0 10%;
      @include m.sp {
        width: 30%;
        padding: 0 0 22%;
      }    
    }
    &--def {
      border: 1px solid v.$gray;
    }
  }
  &_img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  &_detail {
    width: 75%;
    @include m.sp {
      width: 65%;
    }
    &--wide {
      display: flex;
      align-items: baseline;
      width: 81%;
      @include m.sp {
        display: block;
        width: 65%;
      }
    }
  }
  &_title {
    font-size: 14px; 
    @include m.sp {
      font-size: 1.4rem;
    }
  }
}