@use 'global/mixin' as m;
@use 'global/variable' as v;

.c-box {
  &Gray {
    background: v.$gray-thin;
    padding: 40px;
    @include m.sp {
      padding: 20px;
    }
  }
}