@use 'global/mixin' as m;
@use 'global/variable' as v;

html {
  color: v.$black-iron;
  font-family: v.$noto;
  @include m.sp {
    font-size: calc(100vw / 37.5);
  }
}

p {
  line-height: 1.8;
}

.pc {
  @include m.pc {
    display: block;
  }

  @include m.sp {
    display: none;
  }
}

.sp {
  @include m.pc {
    display: none;
  }

  @include m.sp {
    display: block;
  }
}


.screen-reader-text {
  display: none;
}

ul.page-numbers {
  display: flex;
  justify-content: center;
  align-items: center;
}

span.page-numbers {
  font-size: 16px;
  padding: 0 0 10px;
  margin: 0 20px;
}

a.page-numbers {
  font-size: 16px;
  padding: 0 0 10px;
  margin: 0 20px;
  @include m.sp {
    margin: 0 10px;
  }
  &:hover {
    border-bottom: 1px solid v.$black-iron;
  }
}

a.prev.page-numbers,
a.next.page-numbers {
  font-size: 14px;
}

a.prev.page-numbers {
  margin-left: 0;
}

a.next.page-numbers {
  margin-right: 0;
}

span.page-numbers.dots {
  margin: 0;
  &:hover {
    border-bottom: none;
  }
} 

span.page-numbers.current {
  border-bottom: 1px solid v.$black-iron;
}