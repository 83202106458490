@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-wrap {
  padding: 0 20px;
  @include m.sp {
    padding: 0 10px;
  }
  &--contents {
    padding: 70px 20px;
    @include m.sp {
      padding: 60px 10px;
    }
  }
  &_inner {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    &--atc {
      max-width: 800px;
      margin: 0 auto;
    }
    &--crr {
      max-width: 900px;
      margin: 0 auto;
    }
  }
}