@use 'global/mixin' as m;
@use 'global/variable' as v;

.l-atc {
  &Heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px;
    @include m.sp {
      display: block;
      margin: 0 0 10px;
    }
    &_itemLeft {
      @include m.sp {
        margin: 0 0 20px;
      }
    }
    &_itemRight {
      @include m.sp {
        text-align: right;
      }
    }
    &_date {
      margin: 0 20px 0 0;
      @include m.sp {
        margin: 0 10px 0 0;
      }
    }
    &_tag {
      margin: 0 5px 5px 0;
    }
    &_sns {
      margin: 0 10px 0 0;
    }    
  }
  &Mv {
    margin: 0 0 40px;
    @include m.sp {
      margin: 0 0 20px;
    }
  }
  &Sec {
    margin: 0 0 60px;
    @include m.sp {
      margin: 0 0 30px;
    }
    &--crr {
      @include m.sp {
        margin: 0 0 60px;
      }
    }
    &--relate {
      background: rgb(237,245,224);
      background: linear-gradient(157deg, rgba(237,245,224,1) 0%, rgba(255,245,224,1) 100%);
      padding: 70px 20px;
      margin: 0;
      @include m.sp {
        padding: 60px 10px;
      }
    }
    &--contents {
      strong {
        font-weight: bold;
      }
      em {
        font-style: italic;
      }
      a {
        color: v.$green;
        text-decoration:underline;
        text-decoration-color: v.$green;
        @include m.hoverOpa;
      }
      p {
        margin: 30px 0 0;
        @include m.sp {
          font-size: 1.4rem;
          margin: 20px 0 0;
        }
      }
      hr {
        all: revert;
      }
      del {
        all: revert;
      }
      blockquote {
        all: revert;
        background: v.$gray-thin02;
        padding: 15px;
        font-style: italic;
        margin-top: 30px;
        @include m.sp {
          margin-top: 20px;
        }
        *:first-of-type {
          margin: 0;
        }
      }
      ul {
        margin-top: 30px;
        @include m.sp {
          margin-top: 20px;
        }
        li {
          position: relative;
          padding-left: 1rem;
          &:before {
            position: absolute;
            content: "";
            width: 5px;
            height: 5px;
            background: v.$black-iron;
            border-radius: 50%;
            top: 0.6rem;
            left: 0.2rem;
            @include m.sp {
              width: 0.5rem;
              height: 0.5rem;
              top: 0.8rem;
            }
          }
          @include m.sp {
            font-size: 1.4rem;
          }
        }
      }
      ol {
        margin-top: 30px;
        @include m.sp {
          margin-top: 20px;
        }
        list-style-type:decimal;
        list-style-position: inside;
        li {
          &::marker {
            unicode-bidi: isolate;
            font-variant-numeric: tabular-nums;
            text-transform: none;
            text-indent: 0px !important;
            text-align: start !important;
            text-align-last: start !important;
          }
          @include m.sp {
            font-size: 1.4rem;
          }
        }
      }
    }    
  }
  &Para {
    margin: 30px 0;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &SecImgWrap {
    max-width: 600px;
    width: 100%;
    margin: 30px auto 0;
  }
  &SecImgCap {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &BtmSns {
    text-align: center;
    margin: 0 0 70px;
    @include m.sp {
      margin: 0 0 40px;
    }
    &_item {
      margin: 0 10px 0 0;
    }
  }
}

