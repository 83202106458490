@use 'global/mixin' as m;
@use 'global/variable' as v;

.p-interview {
  &Intro {
    margin: 0 0 60px;
    @include m.sp {
      font-size: 1.4rem;
    }
  }
  &Tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 0 0 60px;
    @include m.sp {
      margin: 0 0 30px;
    }
    &--noM {
      margin: 0;
      @include m.sp {
        margin: 0;
      }
    }    
  }
  &BtnWrap {
    text-align: center;
    margin: 0 0 70px;
    @include m.sp {
      margin: 0;
    }
  }
  &Outline {
    margin: 0 0 40px;
    @include m.sp {
      margin: 0 0 30px;
    }
    &_term {
      font-size: 16px;
      font-family: v.$jose;
      font-weight: 300;
      position: relative;
      margin: 0 0 15px;
      margin: 0 0 10px;
      @include m.sp {
        font-size: 1.4rem;
      }
      &:after {
        position: absolute;
        content:"";
        height: 1px;
        width: calc(100% - 60px);
        bottom: 4px;
        right: 0;
        background: v.$black-iron;
        @include m.sp {
          width: calc(100% - 5rem);
        }
      }
    }
    &_title {
      font-size: 14px;
      margin: 0 0 10px;
      @include m.sp {
        font-size: 1.4rem;
      }
    }
  }
}
